th:nth-child(4) {
  width: 240px;
  word-break: break-all;
}
th:nth-child(2) {
  width: 100px;
  word-break: break-all;
}
th:nth-child(3) {
  width: 180px;
  word-break: break-all;
}
th:nth-child(1) {
  width: 160px;
  word-break: break-all;
}